import React, { useState, ChangeEvent, useEffect } from "react";
import Layout from "../components/layout";
import PageTitle from "../components/PageTitle";
import { TransitionContainer } from "../components/TransitionContainer";
import { Highlight } from "../components/Highlight";
import { Typography, Container, Box } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ContactCard } from "../components/ContactCard";
import { graphql } from "gatsby";
import RSSParser from "rss-parser";
import axios from "axios";

type IndexProps = {
  data: any;
};

export default ({ data }: IndexProps) => {
  const [expanded, setExpanded] = useState("panel1");
  const [feed, setFeed] = useState([]);

  const getFeed = async () => {
    const response = await axios.get("/.netlify/functions/rss");
    return response.data.items;
  };
  if (feed && !feed.length) {
    getFeed().then((items) => setFeed(items));
  }

  const handleChange = (panel: string) => (
    _: ChangeEvent,
    expanded: boolean
  ) => {
    setExpanded(expanded ? panel : "");
  };
  return (
    <Layout>
      <PageTitle fluidImage={data.file.childImageSharp.fluid}>
        <TransitionContainer direction="start">
          <Typography variant="h3" component="h1" paragraph>
            Aktuelles
          </Typography>
          <Typography variant="h5">
            Das Steuerrecht ist bekanntlich ständig im Wandel.
            <br />
            Die Kenntnis über aktuelle Änderungen ist daher von
            entscheidender Bedeutung für ihren Erfolg.
          </Typography>
        </TransitionContainer>
      </PageTitle>
      <Container>
        <Box paddingTop={10} paddingBottom={10}>
          {feed &&
            feed.map((item, index) => (
              <ExpansionPanel key={index}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography paragraph>{item.title}</Typography>
                  &nbsp;
                  <Typography>
                    ({new Date(item.pubDate).toLocaleDateString("de")})
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item["content:encoded"],
                      }}
                    ></span>
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
        </Box>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "zaktuelles.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
